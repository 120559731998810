import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import SocialBar from "../components/socialbar"

const Body = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  /*   background-color: green;
 */
  display: flex;
  flex-flow: column nowrap;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 2/ 2 / 3 / 3;
  }
`
const TextWrapper = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  padding: 7%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 2/ 2 / 3 / 3;
    padding-top: 0;
  }
`

const TopText = styled.h1`
  margin-top: 0%;
  font: 700 2em Museo;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`

const BodyText = styled.h3`
  margin-bottom: 0%;
  font: 300 1.5em Museo;
  margin-top: 3%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`

const ImgContainer = styled.div`
  margin-top: 7%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    display: none;
  }
`

const SocialBarWrapper = styled.div`
  margin-bottom: 5%;
  margin-top: 10%;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    display: none;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query BodyQuery {
      img1: file(absolutePath: { regex: "/mug-oval.png/" }) {
        childImageSharp {
          fluid(maxWidth: 100, maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Body>
      <ImgContainer>
        <div>
          <Image
            fluid={data.img1.childImageSharp.fluid}
            style={{
              maxWidth: "40%",
              margin: "auto",
            }}
          />
        </div>
      </ImgContainer>
      <TextWrapper>
        <TopText>Hi, I’m Raúl,</TopText>
        <BodyText>
          I am a Computer Scientist researching Quantum Computing based in the
          beautiful Munich. Here I spend my time figuring out what is the secret
          sauce of Quantum Entrepreneurship.
        </BodyText>
        <BodyText>
          In this site you'll find a collection of thoughts, opinions, and
          unsolicited life advice. Bear with me, it will be an intense and
          rewarding journey.
        </BodyText>
        <SocialBarWrapper>
          <SocialBar />
        </SocialBarWrapper>
      </TextWrapper>
    </Body>
  )
}
